import React, { useState } from "react";
import createQuestion from "../../actions/createQuestion";
import {
  StyledSubmitButton,
  StyledError,
  StyledCancelButton
} from "../../styles/questions";

import { StyledAdminInput } from "../../styles/account";
const CreateQuestion = (props) => {
  const { setIsCreatingQuestion } = props;
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleSubmit = () => {
    createQuestion({ question: value });
    setIsCreatingQuestion(false);
  };

  return (
    <>
      <label>
        <StyledAdminInput
          style={{ marginRight: "10px" }}
          type="text"
          name="question"
          value={value}
          onChange={handleChange}
        />
      </label>
      <StyledSubmitButton onClick={() => handleSubmit()}>
        Create
      </StyledSubmitButton>
      <StyledCancelButton
        style={{ marginRight: "10px" }}
        onClick={() => setIsCreatingQuestion(false)}
      >
        Cancel
      </StyledCancelButton>
    </>
  );
};

export default CreateQuestion;
