import React, { useState, useEffect } from "react";
import Firebase from "firebase/app";
import { isToday } from "date-fns";
import Loader from "../misc/Loader";
import UserContext from "../../UserContext";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import getTime from "./getTime";
import QuestionnaireNotOpen from "./QuestionnaireNotOpen";
import QuestionAlreadyAnswered from "./QuestionAlreadyAnswered";
import QuestionList from "./QuestionList";
import { StyledQuestionsHomeCont } from "../../styles/questions";
import Login from "../auth/Login";
import * as QueryString from "query-string";

import styles from "./questions.module.css";
import { format } from "date-fns";
const parents = require("./parents.json");
const keyedParents = parents.reduce((acc, curr) => {
  if (acc[curr.Email]) {
    acc[curr.Email].push(curr.Child);
  } else {
    acc[curr.Email] = [curr.Child];
  }
  return acc;
}, {});

const QuestionsHome = (props) => {
  const { currentUser } = React.useContext(UserContext);
  const [hourOfDay, setHourOfDay] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = QueryString.parse(props.location.search);
  const unlimited = params.unlimited;
  //TODO: Restrict to quad emails only
  useEffect(() => {
    const getHour = async () => {
      const hour = await getTime();
      setHourOfDay(hour);
    };
    getHour();
  }, []);
  const db = Firebase.firestore();

  // if (window.location.hostname === "localhost") {
  //   console.log("localhost detected!");
  //   db.settings({
  //     host: "localhost:8080",
  //     ssl: false
  //   });
  // }
  const [snapshot, loading, error] = useCollectionOnce(
    db
      .collection("responses")
      .where("createdBy", "==", currentUser ? currentUser.uid : "")
      .orderBy("createdOn", "desc")
  );

  const [userHasAnswered, setUserHasAnswered] = useState(null);

  useEffect(() => {
    if (snapshot) {
      const docs = snapshot.docs.map((doc) => doc.data());
      const latest = docs[0];
      if (currentUser && keyedParents[currentUser.email]) {
        const todaySubmissions = keyedParents[currentUser.email].filter(
          (kid, i) => {
            if (docs[i]) {
              console.log(docs[i].createdOn.toDate());
            }
            if (docs[i] && isToday(docs[i].createdOn.toDate())) {
              return false;
            } else if (docs[i] && !isToday(docs[i].createdOn.toDate())) {
              return true;
            }
            return true;
          }
        );
        console.log(todaySubmissions);
        if (todaySubmissions.length > 0) {
          setUserHasAnswered(false);
        } else {
          setUserHasAnswered(true);
        }
      } else if (latest) {
        // console.log(
        //   new Date(latest.createdOn.toDate()).toLocaleString(),
        //   format(latest.createdOn.toDate(), "M-dd-yyyy h:mm:ss aaaa")
        // );
        // console.log(latest.createdOn.toDate());
        // console.log(isToday(latest.createdOn.toDate()));

        setUserHasAnswered(isToday(latest.createdOn.toDate()));
      } else {
        setUserHasAnswered(false);
      }
    }
  }, [snapshot]);

  // Greedy, order matters
  if (loading || isSubmitting) {
    return (
      <div className={styles.loaderCont}>
        <Loader />
      </div>
    );
  }
  console.log(currentUser);
  if (!currentUser) {
    return <Login />;
  }

  if (hourOfDay === null || userHasAnswered === null) {
    return null;
  }

  // TODO: Enable 12 noon check.
  // if (!hourOfDay || hourOfDay < 5 || hourOfDay > 12) {
  if (!hourOfDay || hourOfDay < 5) {
    return <QuestionnaireNotOpen />;
  }

  if (userHasAnswered && !unlimited) {
    return <QuestionAlreadyAnswered />;
  }
  return (
    <StyledQuestionsHomeCont>
      <h3>COVID-19 Symptoms Questionnaire</h3>
      <QuestionList setIsSubmitting={() => setIsSubmitting(true)} />
    </StyledQuestionsHomeCont>
  );
};

export default QuestionsHome;
