import React, { useEffect, useState } from "react";
import Firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FirestoreCollection } from "react-firestore";
import { format, isToday } from "date-fns";
import Error from "../misc/Error";
import QuadTrackerSubscription from "../misc/QuadTrackerSubscription";
import { InternalLink } from "../../styles/links";
import { Page, StyledFlexVert } from "../../styles/layout";
import { StyledRadios } from "../../styles/radios";
import UserContext from "../../UserContext";
import { InfoCircle } from "@styled-icons/boxicons-solid";
import {
  StyledQuestions,
  StyledSubmitButton,
  StyledError,
  StyledPreamble,
  StyledSubmitCont,
  StyledInputRadio,
  Root,
  Fill
} from "../../styles/questions";
import sendQResponses from "../../actions/sendQResponses";
import styles from "./questions.module.css";
const questions = require("./2021Questions.json");

const InfoIcon = styled(InfoCircle)`
  color: #ff9800;
  width: 18px;
  margin-right: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const validate = () => {
  const all_input = document.querySelectorAll('input[type="radio"]');
  const answerArr = Array.from(all_input);
  const answerTally = answerArr.reduce(
    (acc, curr, idx) => {
      if (curr.dataset.vacc) {
        acc.vaccinated = true;
      }
      if (
        curr.id.includes("yes") &&
        curr.checked &&
        curr.dataset.vacc !== "true"
      ) {
        acc.yesCount += 1;
      }
      if (curr.checked) {
        acc.checked += 1;
      }
      return acc;
    },
    { yesCount: 0, checked: 0, vaccinated: false }
  );
  const { yesCount, checked, vaccinated } = answerTally;
  console.log(yesCount, checked, vaccinated, answerTally);
  return {
    results: yesCount === 0 && checked === answerArr.length / 2,
    vaccinated: vaccinated
  };
};

const validateAllQuestionsAnswered = () => {
  const all_input = document.querySelectorAll('input[type="radio"]');
  const answerArr = Array.from(all_input);
  const checked = answerArr.reduce((acc, curr) => {
    if (curr.checked) {
      acc += 1;
    }
    return acc;
  }, 0);
  return checked === answerArr.length / 2;
};

const QuestionList = ({ setIsSubmitting }) => {
  const [isValid, setIsValid] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [entrance, setEntrance] = useState("");
  let history = useHistory();
  const { currentUser } = React.useContext(UserContext);
  const handleSendResponseClick = async () => {
    if (!validateAllQuestionsAnswered()) {
      setIsValid(false);
      return;
    }
    setIsSubmitting();
    const { results, vaccinated } = validate();
    await sendQResponses({
      pass: results,
      firstName: firstName,
      lastName: lastName,
      entrance: entrance,
      vaccinated: vaccinated
    });
    if (!results) {
      await fetch(
        `https://us-central1-covid-screening-c9bc2.cloudfunctions.net/sendMail?dest=${currentUser.email}&name=${currentUser.displayName}`
      );
    } else if (results) {
      await fetch(
        `https://us-central1-covid-screening-c9bc2.cloudfunctions.net/sendPassingMail?dest=${currentUser.email}&name=${currentUser.displayName}`
      );
    }
    history.push(`/question-answered?result=${results}`);
  };
  const handleOnChange = () => {
    if (isValid === undefined) return;
    if (isValid === false) {
      setIsValid(undefined);
    }
  };

  const isFormFieldsValid = () => {
    return (
      !(firstName.length < 1 || lastName.length < 1) && entrance.length > 1
    );
  };

  return (
    <Page>
      <StyledPreamble>
        <p>
          Thank you for completing our daily COVID survey. This must be
          completed before arriving on campus (parents/guardians should complete
          this survey on their child(ren)’s behalf. If you have any issues with
          the form, please contact{" "}
          <a href="mailto: attendance@quadprep.org">attendance@quadprep.org</a>.
        </p>
      </StyledPreamble>
      <div className={styles.formName}>
        <strong>I am filling out this form on behalf of:</strong>
        <br />
        <div className={styles.instructions}>
          <InfoIcon />

          <span>
            Enter the name of the <strong>STUDENT / STAFF / GUEST</strong>{" "}
            entering the building
          </span>
        </div>
      </div>
      <div className={styles.nameInputs}>
        <div className={styles.inputGroup}>
          <label>First Name</label>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Last Name</label>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <p>
        <strong>School Entrance Used:</strong>
      </p>
      <div className={styles.radioGroup}>
        <div className={styles.radioCont}>
          <Root borderColor="#2196f3" backgroundColor="#fff">
            <StyledInputRadio
              type="radio"
              checked={entrance === "Pine"}
              onChange={() => setEntrance("Pine")}
            />
            <Fill fillColor="#2196f3" />
            {/* <div style={{ marginLeft: '25px' }}>{labelText}</div> */}
          </Root>
          <label>Pine St.</label>
        </div>
        <div className={styles.radioCont}>
          <Root borderColor="#2196f3" backgroundColor="#fff">
            <StyledInputRadio
              type="radio"
              checked={entrance === "Cedar"}
              onChange={() => setEntrance("Cedar")}
            />
            <Fill fillColor="#2196f3" />
            {/* <div style={{ marginLeft: '25px' }}>{labelText}</div> */}
          </Root>
          <label>Cedar St.</label>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          paddingTop: "15px",
          borderTop: "1px solid #eaeaea"
        }}
      >
        {questions.questions.map((question, i) => {
          // keep one question in the backend to avoid deleting collection
          if (i >= 0) {
            const yes = `yes${i}`;
            const no = `no${i}`;
            const choice = `choice${i}`;
            return (
              <>
                <StyledQuestions key={question.id} options={question.options}>
                  {question.options ? (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <span>{question.question}</span>
                      {question.options && (
                        <ul>
                          {question.options.map((option) => {
                            return <li>{option}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <span>{question.question}</span>
                  )}
                  <StyledRadios>
                    <input
                      onChange={handleOnChange}
                      type="radio"
                      name={choice}
                      id={yes}
                      data-vacc={
                        question.question ===
                        "Are you/your child fully vaccinated?"
                      }
                    />
                    <label htmlFor={yes}>Yes</label>
                    <input
                      onChange={handleOnChange}
                      type="radio"
                      name={choice}
                      id={no}
                    />
                    <label htmlFor={no}>No</label>
                    <span className="switchFilter"></span>
                  </StyledRadios>
                </StyledQuestions>
              </>
            );
          }
        })}
        <StyledSubmitCont>
          <StyledSubmitButton
            onClick={handleSendResponseClick}
            disabled={!isFormFieldsValid()}
          >
            Send Response
          </StyledSubmitButton>
          {isValid === false ||
            (!isFormFieldsValid() && (
              <StyledError>Answer all questions</StyledError>
            ))}
        </StyledSubmitCont>
      </div>
    </Page>
  );
};

export default QuestionList;
