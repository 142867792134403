import React from "react";
import styles from "./loader.module.css";
const Loader = () => (
  <div className={styles.ldsRing}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Loader;
