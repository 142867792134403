import styled from "styled-components";

export const StyledRadios = styled.div`
  display: flex;
  align-items: center;
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  label {
    display: inline-block;
    background-color: #eaeaea;
    padding: 4px 20px;
    font-size: 15px;
    border-radius: 4px;
    /* border: 1px solid #555; */
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;

    &:first-of-type {
      margin-right: 10px;
    }
  }

  label:hover {
    background-color: #d4d4d4;
  }

  input[type="radio"]:checked + label {
    background-color: #2196f3;
    color: white;
  }
`;
