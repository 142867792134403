import React from "react";
import { Route } from "react-router-dom";

import logOut from "../../actions/logOut";

const Profile = ({ auth }) => {
  return (
    <Route
      render={({ history }) => (
        <div>
          {auth.photoURL && (
            <img
              src={auth.photoURL}
              alt={auth.displayName}
              width="100"
              height="100"
            />
          )}
          <p>
            <strong>Name:</strong>
            {auth.displayName}
          </p>
          <p>{auth.email}</p>
          <button onClick={() => logOut().then(() => history.push(`/`))}>
            log out
          </button>
        </div>
      )}
    />
  );
};

export default Profile;
