import styled from "styled-components";
import { QuadLogo } from "../views/misc/QuadLogo";
import { UserRectangle } from "@styled-icons/boxicons-solid/UserRectangle";

const HeaderFooterWrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 20px;
  width: 100%;
`;
const Header = styled.div`
  padding: 20px 30px 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    background: white;
    border-bottom: 1px solid #6698c559;
}


`;
const Page = styled.div`
  padding: 1rem;
`;
const Footer = styled.div`
  padding: 1rem;
  text-align: center;
  opacity: 0.3;
`;

const StyledLogo = styled(QuadLogo)`
  width: 210px;
`;

const StyledImg = styled.img`
  width: 36px;
  margin-right: 6px;
`;

const StyledAccountIcon = styled.span`
  font-size: 31px;
`;

const StyledUserIcon = styled(UserRectangle)`
  color: #6699c5;
  height: 28px;
  width: 28px;
`;

const StyledFlexVert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  justify-content: center;

  button {
    margin-bottom: 6px;
  }

  span {
  }
`;

export {
  HeaderFooterWrapper,
  Header,
  Page,
  Footer,
  StyledLogo,
  StyledAccountIcon,
  StyledUserIcon,
  StyledFlexVert,
  StyledImg
};
