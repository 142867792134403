import styled from "styled-components";

export const StyledAdminInput = styled.input`
  width: 350px;
  height: 34px;
`;

export const StyledAdminInputCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    input {
      margin-bottom: 20px;
    }
  }
`;
