import React, { useState } from "react";
import Firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { Show } from "@styled-icons/boxicons-regular";
import { StyledInput, SignUpForm } from "../../styles/auth";
import { StyledSubmitButton } from "../../styles/questions";
import styled from "styled-components";
import styles from "./signup.module.css";
const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [visitedFields, setVisitedFields] = useState([]);
  const [showPW, setShowPW] = useState(false);
  let history = useHistory();

  const StyledShow = styled(Show)`
    color: #333;
    width: 21px;
    cursor: pointer;
  `;

  const validatePassword = () => {
    if (password !== passwordConfirm) return false;
    if (password.length <= 6) return false;
    return true;
  };
  const validateNames = () => {
    return firstName.length > 0 && lastName.length > 0;
  };
  const validateEmail = () => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = () => {
    var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const validateAll = () => {
    return (
      validatePassword() &&
      visitedFields.includes("password") &&
      validatePhoneNumber() &&
      visitedFields.includes("phone") &&
      validateEmail() &&
      visitedFields.includes("email") &&
      validateNames() &&
      visitedFields.includes("name")
    );
  };

  const renderErrorMsg = (type) => {
    if (type === "name" && !validateNames() && visitedFields.includes("name")) {
      return (
        <span className={styles.errorMsg}>
          First and Last name must be included
        </span>
      );
    }
    if (
      type === "email" &&
      !validateEmail() &&
      visitedFields.includes("email")
    ) {
      return <span className={styles.errorMsg}>Must enter a valid email</span>;
    }
    if (
      type === "phone" &&
      !validatePhoneNumber() &&
      visitedFields.includes("phone")
    ) {
      return (
        <span className={styles.errorMsg}>Must enter a valid phone number</span>
      );
    }
    if (
      type === "password" &&
      !validatePassword() &&
      visitedFields.includes("password")
    ) {
      return (
        <span className={styles.errorMsg}>
          Passwords must match and be greater than 6 characters
        </span>
      );
    }
  };

  const handleCreateNewUser = () => {
    Firebase.auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        var user = Firebase.auth().currentUser;
        user
          .updateProfile({
            displayName: `${firstName} ${lastName}`,
            email: email,
            phoneNumber: phoneNumber
          })
          .then(function () {
            console.log("successful user update");
            history.push("/");
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(`Error Code: ${errorCode} Error: ${errorMessage}`);
      });
  };

  return (
    <div className={styles.signUpForm}>
      <div className={styles.card}>
        <h3 style={{ maxWidth: "400px", width: "100%", marginTop: 0 }}>
          Create a Covid Screening Account
        </h3>
        <span className={styles.subtitle}>All fields are required</span>
        <div className={styles.label}>
          <label>First Name</label>
          {renderErrorMsg("name")}
        </div>
        <input
          className={styles.formInput}
          value={firstName}
          onBlur={() => setVisitedFields([...visitedFields, "name"])}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <div className={styles.label}>
          <label>Last Name</label>
          {renderErrorMsg("name")}
        </div>
        <input
          value={lastName}
          className={styles.formInput}
          onBlur={() => setVisitedFields([...visitedFields, "name"])}
          onChange={(e) => setLastName(e.target.value)}
        />
        <div className={styles.label}>
          <label>Email</label>
          {renderErrorMsg("email")}
        </div>
        <input
          className={styles.formInput}
          value={email}
          onBlur={() => setVisitedFields([...visitedFields, "email"])}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={styles.label}>
          <label>Phone Number</label>
          {renderErrorMsg("phone")}
        </div>
        <input
          className={styles.formInput}
          value={phoneNumber}
          onBlur={() => setVisitedFields([...visitedFields, "phone"])}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <div className={styles.label}>
          <label>Choose a Password:</label>
          {renderErrorMsg("password")}
        </div>
        <div className={styles.passwordInput}>
          <input
            type={showPW ? "text" : "password"}
            value={password}
            className={styles.formInputPW}
            onBlur={() => setVisitedFields([...visitedFields, "password"])}
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledShow onClick={() => setShowPW(!showPW)} />
        </div>
        <div className={styles.label}>
          <label>Confirm Password:</label>
          {renderErrorMsg("password")}
        </div>
        <div className={styles.passwordInput}>
          <input
            type={showPW ? "text" : "password"}
            value={passwordConfirm}
            className={styles.formInputPW}
            onBlur={() => setVisitedFields([...visitedFields, "password"])}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <StyledShow onClick={() => setShowPW(!showPW)} />
        </div>
        <div className={styles.buttonContainer}>
          <button
            onClick={() => history.push("/")}
            className={styles.styledCancelButton}
          >
            Cancel
          </button>

          <button
            disabled={!validateAll()}
            className={styles.styledSubmitButton}
            onClick={() => handleCreateNewUser()}
          >
            Create Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
