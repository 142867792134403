// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React, { useState } from "react";
import styles from "./app.module.css";
import FirebaseAuth from "../misc/FirebaseAuth";
import { SignInWithGoogleBtn } from "../../styles/auth";
import { useHistory } from "react-router-dom";
import {
  HeaderFooterWrapper,
  Header,
  Footer,
  StyledLogo,
  StyledImg,
  StyledAccountIcon,
  StyledUserIcon
} from "../../styles/layout";
import { HeaderLink } from "../../styles/links";
import QuadLogo from "../../assets/img/QuadLogo.webp";
import logOut from "../../actions/logOut";
// import { QuadLogo } from "../misc/QuadLogo";

const Layout = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  let history = useHistory();

  return (
    <div
      className={styles.appWrapper}
      style={{ background: isSignedIn ? "#fff" : "#f8f8f8" }}
    >
      <Header>
        <HeaderLink to="/">
          <StyledImg src={QuadLogo} />
          <StyledLogo />
        </HeaderLink>

        <div style={{ float: "right" }}>
          {" "}
          <FirebaseAuth>
            {({ isLoading, error, auth }) => {
              if (isLoading) {
                return null;
              }
              if (error) {
                return "⚠️ login error";
              }
              if (auth) {
                setIsSignedIn(true);

                auth
                  .getIdTokenResult()
                  .then((idTokenResult) => {
                    if (idTokenResult.claims.quadAdmin) {
                      setIsAdmin(true);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                setIsSignedIn(false);
                return null;
              }
              return null;
            }}
          </FirebaseAuth>
          {isAdmin && (
            <HeaderLink to={`/account`}>
              <StyledUserIcon />
            </HeaderLink>
          )}
          {isAdmin === false && isSignedIn && (
            <button
              className={styles.logOutBtn}
              onClick={() => logOut().then(() => history.push(`/`))}
            >
              Log Out
            </button>
          )}
        </div>
      </Header>
      <HeaderFooterWrapper>
        {children}

        <Footer></Footer>
      </HeaderFooterWrapper>
    </div>
  );
};

export default Layout;
