import Firebase from 'firebase/app'
import firebase from 'firebase'

import { FirestoreProvider } from 'react-firestore'
import React, { createContext, useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter, Route } from 'react-router-dom'
import FirebaseAuth from './misc/FirebaseAuth'

import ErrorBoundary from './misc/ErrorBoundary'
import Routes from './Routes'
import Layout from './layout/Layout'
import UserContext from '../UserContext'
import '../styles/global'

const App = () => {
  return (
    <FirestoreProvider firebase={Firebase}>
      <BrowserRouter>
        <ErrorBoundary>
          <FirebaseAuth>
            { ({ isLoading, error, auth, admin }) => {
              return (
                <UserContext.Provider value={{ currentUser: auth }}>
                  <Layout>
                    <Route path="/" component={ScrollToTop}/>
                    <Route path="/" component={Analytics}/>
                    <Routes />
                  </Layout>
                </UserContext.Provider>
              )
            }}
          </FirebaseAuth>
        </ErrorBoundary>
      </BrowserRouter>
    </FirestoreProvider>
  )
}

// scroll to top on route change
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    return null
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({ location }) => {
  const page = location.pathname + location.search
  ReactGA.set({ page })
  ReactGA.pageview(page)
  return null
}

export default App
