import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForCreate } from "./helpers/firestoreHelpers";

const sendQResponses = (value) => {
  ReactGA.event({
    category: "Response",
    action: "Sent Response"
  });

  return Firebase.firestore()
    .collection("responses")
    .add(prepareDocForCreate(value))
    .then(() => value)
    .catch((error) => {
      alert(`Whoops, couldn't create the post: ${error.message}`);
    });
};

export default sendQResponses;
