import React, { useState } from "react";
import firebase from "firebase";
import logIn from "../../actions/logIn";
import { useHistory } from "react-router-dom";
import makeUserAdmin from "../../actions/makeUserAdmin";
import FirebaseAuth from "../misc/FirebaseAuth";
import Error from "../misc/Error";
import Profile from "./Profile";
import {
  StyledSubmitButton,
  StyledError,
  StyledMsg
} from "../../styles/questions";
import Subscription from "./Subscription";
import UserContext from "../../UserContext";
import { SignInWithGoogleBtn } from "../../styles/auth";
import QuestionsAdmin from "../admin/QuestionsAdmin";
import { Page } from "../../styles/layout";
import { StyledAdminInput, StyledAdminInputCont } from "../../styles/account";
const Account = () => {
  const { currentUser } = React.useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [successAdmin, setIsSuccessAdmin] = useState(false);
  const [adminInput, setAdminInput] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(null);
  // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  let history = useHistory();
  return (
    <Page>
      <FirebaseAuth>
        {({ isLoading, error, auth, admin }) => {
          if (isLoading) {
            return <p>loading...</p>;
          }

          if (error) {
            return <Error error={error} />;
          }

          if (!auth) {
            history.push("/login");
          }
          const onAdminChange = (e) => {
            if (isValidEmail === false) {
              setIsValidEmail(null);
            }
            setAdminInput(e.target.value);
          };
          const handleSubmitAdmin = () => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValid = re.test(String(adminInput).toLowerCase());
            if (isValid) {
              addAdmin({
                email: adminInput,
                grant: true
              }).then((result) => {
                setIsSuccessAdmin(true);
              });
            } else {
              setIsValidEmail(false);
            }
          };
          const addAdmin = firebase.functions().httpsCallable("addAdmin");
          const makeAdmin = firebase.functions().httpsCallable("makeAdmin");
          const handleMakeAdmin = () => {
            makeAdmin({
              email: "jweinberg@quadprep.org",
              grant: true
            }).then((result) => {});
          };
          if (currentUser) {
            currentUser
              .getIdTokenResult()
              .then((idTokenResult) => {
                if (idTokenResult.claims.quadAdmin) {
                  setIsAdmin(true);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

          return (
            <div>
              {isAdmin && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px"
                  }}
                >
                  <h3>Add Admin</h3>
                  <StyledAdminInputCont>
                    <StyledAdminInput
                      value={adminInput}
                      onChange={onAdminChange}
                    />
                    <StyledSubmitButton onClick={handleSubmitAdmin}>
                      Make User Admin
                    </StyledSubmitButton>
                    {isValidEmail === false && (
                      <StyledError>Invalid Email</StyledError>
                    )}
                    {successAdmin && (
                      <StyledMsg>Successfully Added Admin</StyledMsg>
                    )}
                  </StyledAdminInputCont>
                </div>
              )}
              <h3>Profile</h3>
              <Profile auth={auth} />
              <hr />
              {isAdmin && (
                <>
                  {" "}
                  <h3>Add Questions</h3>
                  <QuestionsAdmin />
                </>
              )}
            </div>
          );
        }}
      </FirebaseAuth>
    </Page>
  );
};

export default Account;
