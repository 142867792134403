// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from "react";
import { Switch, Route } from "react-router-dom";

import QuestionList from "./questions/QuestionList";
import SignUp from "./auth/SignUp";
import Account from "./account/Account";
import Error from "./misc/Error";
import QuestionAnswered from "./questions/QuestionAnswered";
import QuestionsHome from "./questions/QuestionsHome";
import ForgotPassword from "./auth/ForgotPassword";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={QuestionsHome} />
    <Route exact path="/sign-up" component={SignUp} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route path="/questions" component={QuestionList} />
    <Route path="/question-answered" component={QuestionAnswered} />
    <Route path="/account" component={Account} />
    <Route component={Error} />
  </Switch>
);

export default Routes;
