import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Firebase from "firebase/app";
import styles from "./login.module.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  let history = useHistory();

  const handleInputChange = (e, type) => {
    if (type === "email") setEmail(e.target.value);
  };
  const handleRetrievePW = () => {
    Firebase.auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        setEmailSent(true);
      })
      .catch(function (error) {
        console.error(error);
        if (error.code === "auth/user-not-found") {
          setError("No user with this email exists");
        } else if (error.code === "auth/invalid-email") {
          setError("Invalid Email");
        } else {
          setError(error.code);
        }
        // Error occurred. Inspect error.code.
      });
  };
  return (
    <div className={styles.loginForm}>
      <div className={styles.forgotPWCard}>
        {!emailSent && (
          <>
            <h3>Retrieve Password</h3>
            {error.length > 0 && (
              <span className={styles.errorMsg}>{error}</span>
            )}
            <label>Email: </label>
            <input
              value={email}
              onChange={(e) => handleInputChange(e, "email")}
              className={styles.formInput}
            />
            <div className={styles.buttonContainer}>
              <button
                onClick={handleRetrievePW}
                className={styles.styledSubmitButton}
              >
                Retrieve Password
              </button>
            </div>
          </>
        )}
        {emailSent && (
          <>
            <h3>{`Reset email sent to ${email}`}</h3>
            <div className={styles.buttonContainer}>
              <button
                onClick={() => history.push("/")}
                className={styles.styledSubmitButton}
              >
                Back to Login
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
