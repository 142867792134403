import React from "react";
import styled from "styled-components";

const FlexCenteredDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const QuestionnaireNotOpen = () => (
  <FlexCenteredDiv>
    <h3>The Quad Prep Daily COVID Survey is open 5am-12 noon daily.</h3>
    <span>
      If you have symptoms or to report an absence before 5am, please email{" "}
      <a href="mailto:attendance@quadprep.org">attendance@quadprep.org</a>.
    </span>
  </FlexCenteredDiv>
);

export default QuestionnaireNotOpen;
