/* eslint-disable react/prop-types */
import React from "react";
import { Firestore } from "react-firestore";
import { StyledQuestion, StyledDeleteButton } from "../../styles/questions";
import { Delete } from "@styled-icons/typicons/Delete";

const QuestionItem = (props) => {
  const {
    question: { question, id }
  } = props;
  return (
    <Firestore
      render={({ firestore }) => {
        const deleteQuestion = () => {
          const document = firestore.collection("questions").doc(id);
          document.delete().then(() => {
            console.log("deleted");
          });
        };
        return (
          <StyledQuestion key={`${id}`}>
            <span>{question}</span>
            <StyledDeleteButton onClick={deleteQuestion}>
              <Delete />
            </StyledDeleteButton>
          </StyledQuestion>
        );
      }}
    />
  );
};
export default QuestionItem;
