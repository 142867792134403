import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Firebase from "firebase/app";
import { SignInWithGoogleBtn } from "../../styles/auth";
import logIn from "../../actions/logIn";
import styles from "./login.module.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  let history = useHistory();

  const handleInputChange = (e, type) => {
    if (type === "email") setEmail(e.target.value);
    else setPassword(e.target.value);
  };

  const handleSignIn = () => {
    Firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => history.push("/"))
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(errorCode);
        console.error(errorMessage);
        if (errorCode === "auth/invalid-email") {
          setError("Invalid Email");
        } else if (errorCode === "auth/wrong-password") {
          setError("Incorrect Password");
        } else if (errorCode === "auth/user-not-found") {
          setError("User not found");
        } else if (errorCode === "auth/user-disabled") {
          setError("User disabled");
        }
        // ...
      });
  };
  const getError = () => {
    if (error.length === 0) {
      return null;
    }
    return <span className={styles.errorMsg}>{error}</span>;
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.card}>
        <h3>Sign in to the Quad Covid Screening tool</h3>
        <h4>Sign in with Google or with your email:</h4>
        <div className={styles.logInWithGoogleContainer}>
          <SignInWithGoogleBtn onClick={logIn}></SignInWithGoogleBtn>
        </div>
        <div className={styles.loginForm}>
          {getError()}
          <label>Email</label>
          <input
            value={email}
            onChange={(e) => handleInputChange(e, "email")}
            className={styles.formInput}
          />
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => handleInputChange(e, "password")}
            className={styles.formInput}
          />
          <div className={styles.buttonContainer}>
            <button
              onClick={handleSignIn}
              className={styles.styledSubmitButton}
            >
              Login
            </button>
          </div>
          <div className={styles.authForgotPassword}>
            <Link to="/sign-up">Create Account</Link>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
