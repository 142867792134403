import styled from "styled-components";
import img from "../assets/img/google-signin-light.png";

const SignInWithGoogleBtn = styled.button`
  background-image: url(${img});
  width: 191px;
  background-size: cover;
  border: none;
  height: 46px;
  box-shadow: none;
  background-color: white;
`;

const StyledInput = styled.input`
  border: none;
  box-shadow: none;
  background: #f8f8f8;
  width: 100%;
  max-width: 400px;
  font-size: 16px;
  padding: 10px 10px;
  border-bottom: 1px solid #d6d6d6;
`;

const SignUpForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export { SignInWithGoogleBtn, StyledInput, SignUpForm };
