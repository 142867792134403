// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the  values from the form on submit.

import React, { useState } from "react";
import { FirestoreCollection } from "react-firestore";
import { PlusCircle } from "@styled-icons/boxicons-solid/PlusCircle";
import { AddQuestion, StyledAdminQuestions } from "../../styles/questions";
import { StyledFlexVert } from "../../styles/layout";
import { FormRow, FormLabel, TextInput, TextArea } from "../../styles/forms";
import QuestionItem from "./QuestionItem";
import CreateQuestion from "./CreateQuestion";
const QuestionsAdmin = () => {
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false);
  const handleAddQuestionClick = () => {
    if (isCreatingQuestion) return;
    setIsCreatingQuestion(true);
  };
  return (
    <div>
      <AddQuestion
        disabled={isCreatingQuestion}
        onClick={handleAddQuestionClick}
      >
        <PlusCircle />
        Add Question
      </AddQuestion>
      <FirestoreCollection path={"questions"} sort={"createdOn"}>
        {({ data }) => {
          return (
            <div>
              {data.map((question, i) => {
                return (
                  <>
                    {i > 0 && (
                      <QuestionItem
                        key={`${question.id}-${i}`}
                        question={question}
                      />
                    )}
                  </>
                );
              })}
            </div>
          );
        }}
      </FirestoreCollection>
      {isCreatingQuestion && (
        <CreateQuestion setIsCreatingQuestion={setIsCreatingQuestion} />
      )}
    </div>
  );
};

export default QuestionsAdmin;
