import styled from "styled-components";

const StyledQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  span {
    max-width: 500px;
  }
`;

const AddQuestion = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: ${(props) => (props.disabled ? "#ccc" : "#03a9f4")};
  width: fit-content;
  padding: 4px 10px;
  border-radius: 3px;
  margin-bottom: 20px;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  svg {
    width: 20px;
    margin-right: 5px;
  }
  :hover {
    background: #69c2ea;
  }
`;

const StyledQuestions = styled.div`
  display: flex;
  align-items: ${(props) => (props.options ? "flex-start" : "center")};
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
  span {
    max-width: 550px;
  }
  ul {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    flex-wrap: wrap;
    span {
      max-width: 100%;
      margin-bottom: 15px;
    }
    ul {
      margin-top: 0px;
    }
  }

  &:last-of-type {
    border: none;
    margin-bottom: 0;
    padding-bottom: 6px;
  }
`;

const StyledQuestionsHomeCont = styled.div`
  width: 100%;
  h3 {
    padding-left: 15px;
    margin-bottom: 5px;
  }
`;

const StyledSubmitButton = styled.button`
  padding: 11px 27px;
  font-size: 15px;
  background: #2196f3;
  border: none;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  float: right;
  cursor: pointer;
  
  &:disabled {
  background: #ccc;
  color: #929292;
  pointer: not-allowed;
`;

const StyledCancelButton = styled.button`
  padding: 8px 19px;
  font-size: 15px;
  background: #fff;
  border: none;
  border-radius: 3px;
  color: #333;
  font-weight: 500;
  float: right;
  cursor: pointer;
`;

const StyledError = styled.span`
  font-size: 12px;
  color: salmon;
  float: right;
`;
const StyledMsg = styled.span`
  font-size: 12px;
  color: green;
  float: right;
`;

const StyledDeleteButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: transparent;
  border: none;

  svg {
    height: 21px;
    width: 21px;
  }
`;

const StyledPreamble = styled.div`
  padding-bottom: 5px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
`;

const StyledSubmitCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  justify-content: center;

  margin-top: 15px;

  button {
    margin-bottom: 6px;
  }

  span {
  }
`;

const Root = styled.div`
  width: ${(props) => (props.size ? props.size : 20)}px;
  height: ${(props) => (props.size ? props.size : 20)}px;
  position: relative;

  &::before {
    content: "";
    border-radius: 100%;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : "#DDD")};
    background: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "#FAFAFA"};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  background: ${(props) => (props.fillColor ? props.fillColor : "#A475E4")};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }
`;

const StyledInputRadio = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

export {
  StyledQuestion,
  AddQuestion,
  StyledQuestions,
  StyledQuestionsHomeCont,
  StyledSubmitButton,
  StyledError,
  StyledMsg,
  StyledDeleteButton,
  StyledCancelButton,
  StyledSubmitCont,
  StyledPreamble,
  Root,
  Fill,
  StyledInputRadio
};
