import React from "react";
import styled from "styled-components";

const FlexCenteredDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-align: center;
  justify-content: center;
  padding: 0 20px;
`;

const QuestionAlreadyAnswered = () => (
  <FlexCenteredDiv>
    <h3>You have already answered the questionnaire today.</h3>
  </FlexCenteredDiv>
);

export default QuestionAlreadyAnswered;
