import { format } from "date-fns";
import moment from "moment-timezone";

const getTime = async () => {
  const time = await fetch(
    "https://us-central1-covid-screening-c9bc2.cloudfunctions.net/getTimeStamp"
  );
  const data = await time.json();
  return Number(format(new Date(data.timestamp), "H"));
};

export default getTime;
