import React from "react";
import { Route } from "react-router-dom";
import styles from "./questions.module.css";
import * as QueryString from "query-string";
import { useHistory } from "react-router-dom";

const QuestionAnswered = (props) => {
  const params = QueryString.parse(props.location.search);
  const result = params.result;
  return (
    <Route
      render={({ history }) => (
        <div className={styles.answeredContainer}>
          <div>
            <h3>Thank you for answering the questionaire.</h3>
            {result === "true" && (
              <p>
                You received a passing score! You are cleared to go to school.
              </p>
            )}
            {result === "false" && (
              <>
                <p>
                  You <strong>DID NOT RECEIVE A PASSING SCORE.</strong>
                </p>
                <br />
                <p>
                  We’re so sorry but you can’t come in to School today! Please
                  stay home and we’ll be in touch shortly with next steps.
                </p>
              </>
            )}
          </div>

          <button onClick={() => history.push(`/`)}>Back</button>
        </div>
      )}
    />
  );
};

export default QuestionAnswered;
