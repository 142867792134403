import Firebase from "firebase/app";
import ReactGA from "react-ga";

import { prepareDocForCreate } from "./helpers/firestoreHelpers";

const createQuestion = (value) => {
  ReactGA.event({
    category: "Question",
    action: "Create question"
  });

  return Firebase.firestore()
    .collection("questions")
    .add(prepareDocForCreate(value))
    .then(() => value)
    .catch((error) => {
      alert(`Whoops, couldn't create the post: ${error.message}`);
    });
};

export default createQuestion;
